.root {
  display: flex;
  flex-direction: column;
  position: relative;
  &.topHeader {
    @media (max-width: 767px) {
      padding: 0 !important;
    }
  }
}

.searchOptions {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 0px;
  }
}

.searchResultSummary {
  composes: h4 from global;
  line-height: 20px;

  margin-top: 10px;
  margin-bottom: 11px;
  margin-left: 0px;
  margin-right: 8px;
  max-width: 750px;
  width: 100%;
  @media (max-width: 767px) {
    margin-right: px;
    padding: 0 22px;
  }
}

.loadingResults {
  composes: h4 from global;
  line-height: 20px;

  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  padding: 9px 0 0 0;
  margin: 0;
}

.resultsFound {
  white-space: nowrap;
  color: #000;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 72.727% */
  letter-spacing: -0.2px;
  font-size: 16px;

  @media (--viewportMedium) {
    font-size: 22px;
  }
}

.sortyByWrapper {
  display: inline-flex;
  margin-left: auto;
}

.sortyBy {
  composes: marketplaceSmallFontStyles from global;
  font-weight: var(--fontWeightRegular);
  padding: 8px 8px 8px 0;
}

.categoryWrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 1023px) {
    & > div:not(:last-child) {
      position: relative;
      &:after {
        position: absolute;
        content: '';
        width: 1px;
        height: 37.955px;
        background: var(--marketplaceColorDark);
        right: -15px;
        top: 15px;
      }
    }
  }
  & > div:nth-child(1) {
    & .activeStore {
      & .icon {
        background: var(--marketplaceColorDark);
        border-radius: 50%;
      }
    }
  }
  & > div:nth-child(2) {
    & .activeStore {
      & .icon {
        background: #d7282f;
        border-radius: 50%;
      }
    }
  }
  & > div:nth-child(3) {
    & .activeStore {
      & .icon {
        background: #e87200;
        border-radius: 50%;
      }
    }
  }
  & > div:nth-child(4) {
    & .activeStore {
      & .icon {
        background: #61a60e;
        border-radius: 50%;
      }
    }
  }
  & * {
    color: var(--matterColor);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 32px;
    @media (max-width: 1023px) {
      font-size: 14px;
    }
  }
}

.categoryItem {
}

.iconWrapper {
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  @media (max-width: 1023px) {
    flex-direction: column;
    gap: 6px;
  }
  & .icon {
    height: 53px;
    width: 53px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.activeStore {
  }
}

.tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--marketplaceColor);
  background: #f8f7f7;
  max-width: 500px;
  width: 100%;
  padding: 15px 40px 0;
  @media (max-width: 1023px) {
    padding: 15px 40px 0;
  }
  @media (max-width: 767px) {
    border: 0px solid var(--marketplaceColor);
    border-top: 1px solid var(--marketplaceColor);
    border-bottom: 1px solid var(--marketplaceColor);
    border-radius: 0;
    max-width: 100vw;
  }
  & .tabItems {
    color: var(--marketplaceColor);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 111.111% */
    letter-spacing: -0.305px;
    padding-bottom: 20px;
    border-bottom: 4px solid transparent;
    padding: 0 15px 15px;
    @media (max-width: 1023px) {
      font-size: 16px;
      padding: 0 15px 15px;
    }
    &.activeTabItems{
      font-weight: 900;
      cursor: pointer;
      border-bottom: 4px solid var(--marketplaceColor);
    }
    &:hover {
      font-weight: 900;
      cursor: pointer;
      border-bottom: 4px solid var(--marketplaceColor);
    }
  }
}

.searchResult {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
