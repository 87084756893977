@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  /* sticky result summary in mobile */
  position: sticky;
  top: 0;
  z-index: 1;
}

.searchResultSummary {
  composes: h3 from global;
  margin-top: 6px;

  display: flex;
  justify-content: space-between;

  background-color: var(--matterColorBright);

  @media (--viewportMedium) {
    margin-top: 8px;
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
  & > button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 100px;
    border: 1px solid #e6e6e6;
    background: var(--marketplaceColorDark);
    min-height: 40px;
    color: #fff;
    white-space: nowrap;
    &:last-child {
      margin-right: 0 !important;
    }
  }
}

.filtersButton {
  composes: button marketplaceTinyFontStyles buttonText buttonBordersSecondary buttonColorsSecondary from global;
  letter-spacing: 0.2px;
  font-weight: var(--fontWeightMedium);

  /* Avoid stretching button width. */
  flex-basis: 0;

  height: 35px;
  min-height: 35px;
  padding: 0 18px;
  margin: 0 9px 0 0;
  border-radius: 4px;
}

.filtersButtonSelected {
  composes: button marketplaceTinyFontStyles buttonText buttonBorders buttonColors from global;
  letter-spacing: 0.2px;
  font-weight: var(--fontWeightMedium);

  /* Avoid stretching button width. */
  flex-basis: 0;

  height: 35px;
  min-height: 35px;
  padding: 0 18px;
  margin: 0 9px 0 0;
  border-radius: 4px;
}

.mapIcon {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* background map image */
  background-image: url(./images/map_icon216x105.png);
  background-size: cover;

  border-radius: 3px;
  width: 72px;
  height: 35px;
  padding: 9px 22px;
  margin: 0;
}

.modalContainer {
  width: 100%;
  padding: 33px 0 95px;
  margin: 0 24px;
  & > button {
    padding: 0 !important;
    top: 40px;
    right: 22px;
  }
}

.modalHeadingWrapper {
  padding-bottom: 24px;
  border-bottom: 1px solid var(--matterColorNegative);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 22px;
}

.modalHeading {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #000;
}

.resetAllButton {
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColor);
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 33px; /* 235.714% */
  text-decoration-line: underline;
  /* Layout */
  display: inline;
  margin: 0px;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }
}

.filtersWrapper {
  /* add bottom margin so that the last filter won't be hidden
   * under the mobile safari bottom bar or the "Show results"
   * button bar */
  padding-bottom: 220px;
  padding: 0 22px 30px 0;
  max-height: calc(100vh - 180px);
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
    background: #edf5fa;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--marketplaceColorDark);
    border-radius: 6px;
    outline: 1px solid #fff;
  }
}

.showListingsContainer {
  position: fixed;
  bottom: 0;
  left: 0;

  width: 100vw;
  padding: 18px 24px;

  background: var(--matterColorLight);
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  z-index: 11;
}

.showListingsButton {
  background-color: var(--marketplaceColor);
  display: inline-block;
  border-radius: 3px;
}
